<template>
  <div>
    <v-row class="mt-3 pt-5">
      <v-col cols="auto" align-self="center">
        <v-btn icon to="/verein" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>mdi-account-group</v-icon>
      </v-col>
      <v-col align-self="center">
        <h3 class="white--text font-weight-bold">
          BENUTZERVERWALTUNG
        </h3>
      </v-col>
      <v-col cols="auto">
        <v-btn
          :fab="$vuetify.breakpoint.smAndDown"
          :text="$vuetify.breakpoint.mdAndUp"
          to="/verein/erstelle-benutzer"
        >
          <v-icon class="mr-2">mdi-account-plus</v-icon>
          <span v-if="$vuetify.breakpoint.mdAndUp">Neuer Benutzer</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-row justify="center" v-if="benutzer.length > 0">
        <v-col cols="11" class="pa-0 ma-0" v-for="m in benutzer" :key="m.id">
          <carduser
            :name="m.vorname + ' ' + m.nachname"
            :teams="m.team"
            :rolle="m.rolle"
            :disabled="user.data.rolle != 'Vorstand'"
            :textcolor="template.colors.block_text"
            :bgcolor="template.colors.blocks"
            upper
            :to="`/verein/benutzerverwaltung/${m.id}`"
          />
        </v-col>
      </v-row>
      <v-col cols="12" v-if="benutzer.length == 0">
        <v-card dark>
          <v-row style="min-height: 30vh;" justify="center">
            <v-col align-self="center" class="text-center">
              <h3 class="font-weight-light">
                Es wurden derzeit noch keine weiteren Benutzer für deinen Verein
                angelegt.
              </h3>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../../firebaseConfig'
import store from '../../../store'
import carduser from '../../../elements/cards/card-user'

export default {
  name: 'Anwesenheitsliste',
  data() {
    return {
      benutzer: [],
      generating: false,
      datum: '',
      adatum: false,
    }
  },
  components: {
    carduser,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  mounted() {
    firebase
      .firestore()
      .collection('User')
      .where('verein.id', '==', this.user.data.uid)
      .orderBy('vorname')
      .onSnapshot((snap) => {
        this.benutzer = []
        snap.forEach((doc) => {
          this.benutzer.push(doc.data())
          var index = this.benutzer.length - 1
          this.benutzer[index].id = doc.id
          if (doc.data().team) {
            if (!Array.isArray(doc.data().team)) {
              this.benutzer[index].team = []
              this.benutzer[index].team.push(doc.data().team)
            }
          } else {
            this.benutzer[index].team = []
          }
        })
      })
  },
  methods: {
    addNull(val) {
      if (val > 10) {
        return val
      }
      return '0' + val
    },
    load_datum() {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Anwesenheit')
        .where('datum', '==', this.datum)
        .get()
        .then((snap) => {
          this.anwesenheit = []
          snap.forEach((doc) => {
            this.anwesenheit.push(doc.data())
            this.anwesenheit[this.anwesenheit.length - 1].id = doc.id
          })
        })
    },
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    generatePDF() {
      if (this.anwesenheit.length > 0) {
        this.generating = true
        this.$refs.Anwesenheit.generatePdf()
        this.$emit('generated', true)
        setTimeout(() => {
          this.generating = false
        }, 1000)
      }
    },
  },
}
</script>
>
