<template>
  <v-card
    class="rounded-xl my-4"
    :height="height"
    :elevation="elevate ? elevate : 8"
    :color="bgcolor"
    :style="`color: ${textcolor};`"
    :loading="loading"
    :disabled="disabled"
    link
    :to="to"
    @click="$emit('click')"
  >
    <v-row justify="center" class="px-2 h-100">
      <v-col
        :cols="twoline ? 12 : 2"
        align-self="center"
        :class="twoline ? 'mb-0 pb-1' : ''"
      >
        <v-avatar class="my-auto" large>
          <v-icon
            large
            :style="`background-color: ${textcolor}; color: ${bgcolor};`"
          >
            mdi-account
          </v-icon>
        </v-avatar>
      </v-col>
      <v-col cols="8" align-self="center">
        <span class="font-weight-bold" :class="upper ? 'text-uppercase' : ''">
          {{ name }}
        </span>
        <span class="font-weight-bold text-uppercase">
          <br />
          <v-chip small class="mx-1" :color="textcolor">
            {{ rolle }}
          </v-chip>
          <v-chip
            small
            class="mx-1"
            outlined
            :color="textcolor"
            v-for="(t, index) in teams"
            :key="index"
          >
            {{ t }}
          </v-chip>
        </span>
      </v-col>
      <v-col cols="2" align-self="center" class="text-right">
        <v-icon large :color="textcolor">mdi-chevron-right</v-icon>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'buttoncard',
  props: {
    title: String,
    icon: String,
    textcolor: String,
    bgcolor: String,
    teams: Array,
    chev: Boolean,
    chevleft: Boolean,
    upper: Boolean,
    vereinslogo: String,
    verein: String,
    rolle: String,
    name: String,
    to: String,
    twoline: Boolean,
    loading: Boolean,
    disabled: Boolean,
    elevate: Number,
    height: String,
  },
  created() {
    if (this.teams) {
      var t = this.teams
      this.teams = []
      t.forEach((m) => {
        if (m.length > 0) {
          this.teams.push(m)
        }
      })
    }
  },
  methods: {
    parseDate(date) {
      var datum = date.split('-')
      var datum_obj = new Date(date)
      var weekdays = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
      var weekday = datum_obj.getDay()
      return (
        weekdays[weekday] + ', ' + datum[2] + '.' + datum[1] + '.' + datum[0]
      )
    },
  },
}
</script>
